<template>
    <footer class="bg-white dark:bg-gray-900">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
            <!-- Top area: Blocks -->
            <div class="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200 dark:border-gray-800">
                <!-- 1st block -->
                <div class="sm:col-span-12 lg:col-span-3">
                    <div class="mb-2">
                        <!-- Logo -->
                        <NuxtLink class="inline-block" to="/" aria-label="HSA Planner Home">
                            <NuxtImg 
                                style="width: 4rem;" 
                                src="/favicon.png" 
                                alt=""
                            />
                        </NuxtLink>
                    </div>
                    <div class="text-sm text-gray-600 dark:text-gray-200">
                        <NuxtLink
                            class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                            to="/disclosure/">Terms</NuxtLink> · <NuxtLink
                            class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                            to="/disclosure/#privacy">Privacy Policy</NuxtLink>
                    </div>
                </div>

                <!-- 2nd block -->
                <div class="sm:col-span-6 md:col-span-3 lg:col-span-4">
                    <h3 class="text-gray-800 dark:text-gray-200 font-medium mb-2">Products / Tools</h3>
                    <ul class="text-sm">
                        <li class="mb-2">
                            <NuxtLink
                                class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                                to="/hsaguidedportfolio/">HSA Guided Portfolio</NuxtLink>
                        </li>
                        <li class="mb-2">
                            <NuxtLink
                                class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                                to="/balanceprojection/">Balance Projection Calculator</NuxtLink>
                        </li>
                        <li class="mb-2">
                            <NuxtLink
                                class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                                to="/payrollsavingscalculator/">HSA Payroll Tax Savings Calculator</NuxtLink>
                        </li>
                    </ul>
                </div>

                <!-- 3rd block -->
                <div class="sm:col-span-6 md:col-span-3 lg:col-span-4">
                    <h3 class="text-gray-800 dark:text-gray-200 font-medium mb-2">Company</h3>
                    <ul class="text-sm">
                        <li class="mb-2">
                            <NuxtLink
                                class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                                to="/about/">About Us</NuxtLink>
                        </li>
                        <li class="mb-2">
                            <NuxtLink
                                class="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 transition duration-150 ease-in-out"
                                to="https://www.devenir.com" target="_blank" rel="noopener noreferrer">Devenir.com</NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Bottom area -->
            <div class="md:flex md:items-center md:justify-between py-3 md:py-5 border-t border-gray-200 dark:border-gray-800">
                <nav>
                    <!-- Social links -->
                    <ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
                        <li>
                            <NuxtLink
                                class="flex justify-center items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 p-4 md:p-2"
                                to="https://twitter.com/HSAPlanner" aria-label="HSA Planner on Twitter" target="_blank" rel="noreferrer">
                                <svg class="w-5 h-5 fill-current" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M75.916 54.2L122.542 0h-11.05L71.008 47.06L38.672 0H1.376l48.898 71.164L1.376 128h11.05L55.18 78.303L89.328 128h37.296L75.913 54.2ZM60.782 71.79l-4.955-7.086l-39.42-56.386h16.972L65.19 53.824l4.954 7.086l41.353 59.15h-16.97L60.782 71.793Z" />
                                </svg>
                            </NuxtLink>
                        </li>
                        <li class="md:ml-4">
                            <NuxtLink
                                class="flex justify-center items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-200 p-4 md:p-2"
                                href="mailto:hsa@devenir.com" aria-label="Email HSA Planner">
                                <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5l-8-5V6l8 5l8-5v2z" />
                                </svg>
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>

                <!-- Copyrights note -->
                <div class="text-sm text-gray-600 dark:text-gray-300">&copy; Devenir Group, LLC. All rights reserved.</div>
            </div>
        </div>
    </footer>
</template>