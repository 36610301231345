<template>
    <header
        class="fixed w-full z-40 md:bg-opacity-90 bg-white dark:bg-neutral-900 dark:md:bg-opacity-95 transition duration-300 ease-in-out">
        <div class="max-w-6xl mx-auto px-5 sm:px-6">
            <div class="flex items-center justify-between h-16 md:h-20">

                <div class="flex-shrink-0">
                    <NuxtLink class="block" to="/" aria-label="HSA Planner Home">
                        <NuxtImg id="logo" class="block dark:hidden" src="/imgs/hsaplanner_80px.png" :width="270" alt="HSA Planner" />
                        <NuxtImg id="logo-light" class="hidden dark:block" src="/imgs/hsaplanner_80px_white.png" :width="270" alt="HSA Planner" />
                    </NuxtLink>
                </div>

                <!-- Hamburger button -->
                <button
                    class="md:hidden"
                    name="Mobile Menu Toggle"
                    :aria-label="isMobileMenuOpen ? 'Close menu' : 'Open menu'"
                    aria-expanded="false"
                    @click="toggleMobileMenu">
                    <span class="sr-only">{{ isMobileMenuOpen ? 'Close menu' : 'Open menu' }}</span>
                    <!-- Icon for menu button -->
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>

                <!-- Desktop navigation -->
                <nav class="hidden md:flex md:grow" aria-label="Main navigation">

                    <!-- Desktop menu links -->
                    <ul class="flex grow justify-end flex-wrap items-center">
                        <li class="relative">
                            <NuxtLink
                                class="text-md lg:text-lg font-semibold flex items-center gap-1 hover:text-primary"
                                to="/hsaguidedportfolio/">HSA Guided Portfolio</NuxtLink>
                        </li>
                        <li class="relative">
                            <NuxtLink
                                class="text-md lg:text-lg font-semibold flex items-center gap-1 hover:text-primary px-3 lg:px-5"
                                to="/about/">About</NuxtLink>
                        </li>
                    </ul>

                    <!-- Desktop sign in links -->
                    <ul class="flex grow justify-end flex-wrap items-center">
                        <li>
                            <NuxtLink class="ml-3" href="mailto:hsa@devenir.com?subject=Learn more about HSA Planner">
                                <UButton color="black" size="lg" icon="i-heroicons-pencil-square" :trailing="true">Contact
                                    Us</UButton>
                            </NuxtLink>
                        </li>
                    </ul>

                </nav>

                <!-- Mobile Menu Overlay -->
                <Transition name="fade">
                <div
                    v-if="isMobileMenuOpen"
                    class="fixed inset-0 bg-black bg-opacity-50 z-30"
                    @click="closeMobileMenu" />
                </Transition>

                <!-- Mobile Menu -->
                <Transition name="slide">
                <div
                    v-if="isMobileMenuOpen"
                    class="fixed inset-y-0 left-0 transform bg-white w-64 z-40 overflow-auto dark:bg-black"
                    :class="{ 'translate-x-0': isMobileMenuOpen, '-translate-x-full': !isMobileMenuOpen }"
                >
                    <button class="p-4" aria-label="Close Mobile Menu" @click="closeMobileMenu">
                        <!-- Exit icon -->
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <!-- Menu content -->
                    <nav>
                        <ul class="p-4">
                            <li class="relative mb-2">
                                <NuxtLink class="text-lg font-semibold hover:text-primary" to="/" @click="closeMobileMenu">Home</NuxtLink>
                            </li>
                            <li class="relative mb-2">
                                <NuxtLink class="text-lg font-semibold hover:text-primary" to="/hsaguidedportfolio/" @click="closeMobileMenu">HSA Guided Portfolio</NuxtLink>
                            </li>
                            <li class="relative">
                                <NuxtLink class="text-lg font-semibold hover:text-primary" to="/about/" @click="closeMobileMenu">About</NuxtLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Transition>
            </div>
        </div>
    </header>
</template>
<script setup>
const isMobileMenuOpen = ref(false);

const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const closeMobileMenu = () => {
    isMobileMenuOpen.value = false;
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>